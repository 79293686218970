import React, { useContext, useState } from 'react'
import './HomeNavLinks.css'
import { NavLink, Link, useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from '../../context/auth-context'
//
//
import PBHL_BYLAWS from './../../../../src/PBHL_BYLAWS_MARCH_2024.pdf'

const HomeNavLinks = (props) => {
	const auth = useContext(AuthContext)
	const [loggedOut, setLoggedOut] = useState(false)
	//
	let navigate = useNavigate()
	//
	//
	//
	//
	//
	let coachId
	if (auth.coachIsLoggedIn) {
		const test = localStorage.getItem('id')
		coachId = test
	}
	//
	//
	//
	//
	const logoutStuff = () => {
		setLoggedOut(true)
	}

	if (loggedOut) {
		navigate('/')
		auth.coachLogout()
	}

	const propsString = props.leagueId

	let page
	const rulesURL =
		'https://usaballhockey.com/wp-content/uploads/USA-Ball-Hockey-Official-Rule-Book-Revised-07.06.2021.pdf'

	if (auth.isLoggedIn) {
		page = '/admin/registration'
	} else {
		page = '/admin/login'
	}
	//
	//
	//
	//
	//
	//
	return (
		<ul className='home-nav-links'>
			<li>
				<NavLink to={'/info'}>PBHL INFO</NavLink>
			</li>
			<li>
				<NavLink to={'/conduct'}>COACH CODE OF CONDUCT</NavLink>
			</li>
			{/* <li>
				<NavLink to={PBHL_BYLAWS} target='_blank' rel='noreferror'>
					BY-LAWS
				</NavLink>
			</li>
			<li>
				<NavLink to={rulesURL} target='blank'>
					RULES
				</NavLink>
			</li> */}
			<li>
				<NavLink to={'/rosters'}>ROSTERS</NavLink>
			</li>
			{/* //
			//
			//  MATT:  Uncomment these two 'REGISTER YOUR TEAM' links for the beginning of 2026
			//    or whenever Studeny tells me
			// */}
			{/* {auth.coachIsLoggedIn && (
				<li>
					<NavLink to={`/teamRegistration/${coachId}`}>
						REGISTER YOUR TEAM
					</NavLink>
				</li>
			)}
			{!auth.coachIsLoggedIn && (
				<li>
					<NavLink to={'/register'}>REGISTER YOUR TEAM</NavLink>
				</li>
			)} */}
			<li>
				<a href='/'>HOME</a>
			</li>
			<li>
				<NavLink to={page}>ADMIN</NavLink>
			</li>
			{auth.coachIsLoggedIn && (
				<li>
					<button
						onClick={logoutStuff}
						style={{ border: 'none', fontWeight: 'bold' }}
					>
						LOGOUT
					</button>
				</li>
			)}
		</ul>
	)
}

export default HomeNavLinks
